<template>
  <UiDialog :max-width="400" persistent :dialog="active">
    <template v-if="title" v-slot:header>
      <UiTitle :title="title" />
    </template>
    <template v-if="body" v-slot:body>
      <div class="tw-px-4 tw-py-6 sm:tw-px-6" v-dompurify-html="body" />
    </template>
    <template v-slot:actions>
      <v-btn text color @click="closeDialog()" type="submit" rounded>{{ cancelLabel }}</v-btn>
      <v-spacer />
      <v-btn @click="confirm()" type="submit" rounded color="primary" :loading="loading" :disabled="loading">{{
        confirmLabel
      }}</v-btn>
    </template>
  </UiDialog>
</template>

<script>
import UiDialog from '@/components/UI/Dialog.vue'
import UiTitle from '@/components/UI/Title.vue'

export default {
  name: 'UiConfirmOverlay',
  components: {
    UiTitle,
    UiDialog,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    active: {
      type: Boolean,
      required: true,
    },
    body: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    cancelLabel: {
      type: String,
      required: false,
      default: function () {
        // arrow functions unbind 'this'
        return this.$t('button.cancel')
      },
    },
    confirmLabel: {
      type: String,
      required: false,
      default: function () {
        return this.$t('button.confirm')
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>
