<template>
  <v-navigation-drawer
    :value="drawer"
    absolute
    temporary
    right
    :width="$vuetify.breakpoint.xsOnly ? '100%' : 320"
    @input="toggleFilters"
  >
    <template v-slot:prepend>
      <v-toolbar class="primary tw-flex-grow-0" height="56" :elevation="2">
        <v-btn icon dark @click="toggleFilters(false)" v-if="$vuetify.breakpoint.xsOnly">
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
        </v-btn>
        <v-toolbar-title class="tw-text-base tw-font-medium tw-text-white">
          {{ $t('profilehub.contacts.filters.title') }}
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <div class="tw-p-4">
      <div v-if="!filters.length">{{ $t('profilehub.contacts.filters.noFilter') }}</div>
      <div class="tw-grid tw-gap-10">
        <div
          class="tw-flex tw-items-center tw-gap-4 tw-overflow-x-hidden"
          v-for="(filter, idx) in filters"
          :key="`filter${idx}`"
        >
          <div class="tw-flex tw-flex-col tw-gap-4 tw-overflow-x-hidden">
            <v-autocomplete
              v-model="filter.slug"
              solo
              flat
              outlined
              :items="computedCriterias"
              item-text="label"
              item-value="slug"
              :label="computedCriterias.slug"
              :placeholder="$t('profilehub.contacts.filters.selectCriteria')"
              hide-details
              @change="resetValues(filter)"
            />
            <div class="tw-flex tw-items-center tw-gap-2">
              <v-icon>{{ icons.mdiArrowRightBottom }}</v-icon>
              <v-autocomplete
                v-model="filter.values"
                class="tw-overflow-x-hidden"
                solo
                flat
                outlined
                :items="valuesByCriteria(filter.slug)"
                item-text="label"
                item-value="slug"
                multiple
                chips
                clearable
                deletable-chips
                :label="$t('profilehub.contacts.filters.selectValue')"
                :placeholder="$t('profilehub.contacts.filters.selectValue')"
                hide-details
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    color="primary"
                    close
                    @click:close="removeItem(data.item.slug ? data.item.slug : data.item, filter)"
                  >
                    <span class="text-truncate"> {{ data.item.label ? data.item.label : data.item }}</span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </div>
          </div>

          <v-btn icon @click="removeFilter(filter)">
            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
          </v-btn>
        </div>
      </div>
      <a @click="addFilter" class="tw-flex tw-mt-6">
        <v-icon left color="primary"> {{ icons.mdiPlus }}</v-icon>
        {{ $t('profilehub.contacts.filters.addFilters') }}
      </a>
    </div>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block color="primary" text rounded @click="filterView" :disabled="computedValidate">
          {{ $t('button.validate') }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mdiPlus, mdiArrowLeft, mdiDeleteOutline, mdiArrowRightBottom } from '@mdi/js'
import { clone } from '@/utils/utilities.util'

let initialData = () => ({
  filters: [],
})

export default {
  name: 'ProfileHubContactsFilters',
  props: {
    criteria: {
      type: Array,
      required: false,
      default: () => [],
    },
    segment: {
      type: Object,
      required: true,
    },
    drawer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    icons: { mdiPlus, mdiArrowLeft, mdiDeleteOutline, mdiArrowRightBottom },
    ...initialData(),
  }),
  watch: {
    segment: {
      handler() {
        this.initFilters()
      },
      deep: true,
    },
  },
  computed: {
    computedCriterias() {
      return this.criteria.filter(crit => Array.isArray(crit.value))
    },
    computedValidate() {
      const clearedFilters = this.filters.filter(filter => filter.values?.length !== 0)
      return clearedFilters.length === 0
    },
  },
  methods: {
    toggleFilters(bool) {
      this.$emit('toggle', bool)
      if (bool) {
        this.initFilters()
      }
    },
    removeFilter(filter) {
      this.filters = this.filters.filter(f => JSON.stringify(f) !== JSON.stringify(filter))
    },
    filterView() {
      this.$emit('filter', this.filters)
      this.$emit('close')
      this.resetData()
    },
    resetData() {
      Object.assign(this.$data, initialData())
    },
    addFilter() {
      this.filters.push({
        slug: '',
        values: [],
        operator: 'in',
      })
    },
    valuesByCriteria(selectedCriteria) {
      if (selectedCriteria) {
        const criteriaValues = this.computedCriterias.find(crit => crit.slug === selectedCriteria)
        return criteriaValues ? criteriaValues.value : []
      }
    },
    initFilters() {
      this.filters = clone(this.segment.rules?.filters) || []
    },
    removeItem(item, filter) {
      filter.values = filter.values.filter(v => v !== item)
    },
    resetValues(filter) {
      filter.values = []
    },
  },
}
</script>
