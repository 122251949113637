<template>
  <UiDialog :dialog="dialog" @close="closeDialog()">
    <template class="required-asterisk" v-slot:header>
      <UiTitle :title="$t('profilehub.contacts.modal.nameSegment.title')" />
      <UiSubtitle :subtitle="$t('profilehub.contacts.modal.nameSegment.subtitle')" />
    </template>
    <template v-slot:body>
      <div class="tw-px-4 tw-pt-6 sm:tw-px-6">
        <v-text-field
          @keypress.enter="onSubmit"
          v-model="name"
          :error-messages="nameError"
          solo
          flat
          outlined
          :placeholder="$t('profilehub.contacts.modal.nameSegment.placeholder.name')"
        >
        </v-text-field>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn type="submit" rounded text @click="$emit('close')">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-spacer />
      <v-btn @click="onSubmit" type="submit" rounded color="primary" :loading="loading" :disabled="loading">{{
        $t('button.save')
      }}</v-btn></template
    >
  </UiDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiDialog from '@/components/UI/Dialog.vue'

export default {
  name: 'ProfileHubContactsNameSegments',
  components: {
    UiTitle,
    UiSubtitle,
    UiDialog,
  },
  mixins: [validationMixin],
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    segment: {
      type: Object,
      required: false,
    },
    mode: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    name: '',
  }),
  watch: {
    segment: {
      handler() {
        this.name = this.segment ? this.segment.name : ''
      },
      deep: true,
    },
  },
  computed: {
    nameError() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push(this.$t('error.required'))
      !this.$v.name.minLength &&
        errors.push(
          this.$t('error.minLength', {
            minLength: this.$v.name.$params.minLength.min,
          })
        )
      return errors
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', { ...this.segment, name: this.name }, this.mode)
      }
    },
  },
  validations() {
    return {
      name: {
        required,
        minLength: minLength(5),
      },
    }
  },
}
</script>
