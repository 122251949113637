<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else class="profile-hub-contacts-list">
      <v-navigation-drawer
        v-model="drawerSegments"
        absolute
        clipped
        left
        class="profile-hub-contacts-list__segments"
        @input="toggleSegments"
      >
        <v-list nav dense class="profile-hub-contacts-list__segments__list">
          <v-list-item-group color="primary" :value="currentSegmentName" mandatory>
            <v-subheader class="primary--text tw-font-bold tw-uppercase"
              >{{ $t('profilehub.contacts.navigation.default.title') }}
            </v-subheader>
            <v-list-item :value="null" @click="updateCurrentSegment(null)">
              <v-list-item-title> {{ $t('profilehub.contacts.navigation.default.all') }} </v-list-item-title>
            </v-list-item>
            <v-subheader class="tw-mt-6 primary--text tw-font-bold tw-uppercase">
              {{ $t('profilehub.contacts.navigation.custom.title') }}
            </v-subheader>
            <div class="profile-hub-contacts-list__segments__list__action tw-px-2 tw-mb-4">
              <v-btn @click="addNewSegment()" :disabled="tmpSegment" outlined rounded color="primary" block>
                <v-icon left>{{ icons.mdiEyePlusOutline }}</v-icon>
                {{ $t('profilehub.contacts.navigation.custom.add') }}
              </v-btn>
            </div>
            <v-list-item
              v-for="(segment, idx) in segments"
              :key="`segment${idx}`"
              :value="segment.name"
              @click="updateCurrentSegment(segment)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ segment.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="segment.saved === false" class="tw-justify-end">
                <v-icon small>{{ icons.mdiContentSaveAlertOutline }}</v-icon>
              </v-list-item-action>
            </v-list-item>
            <div class="tw-text-center tw-mt-4" v-if="segments.length === 0">
              <v-icon x-large>{{ icons.mdiEyeOffOutline }}</v-icon>
              <div class="tw-text-gray-500 dark:tw-text-gray-400 tw-text-xs">
                {{ $t('profilehub.contacts.navigation.custom.empty') }}
              </div>
            </div>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <div
        class="profile-hub-contacts-list__content"
        :class="{ 'profile-hub-contacts-list__content--expanded': drawerSegments }"
      >
        <UiBanner>
          <template v-slot:content>
            <div class="tw-flex tw-items-center">
              <v-btn
                icon
                @click="drawerSegments = !drawerSegments"
                class="tw--ml-2 tw-mr-4"
                :class="{ 'tw-transform  tw-rotate-180': !drawerSegments }"
              >
                <v-icon>{{ icons.mdiMenuOpen }}</v-icon>
              </v-btn>
              <div>
                <UiTitle
                  :title="currentSegmentName ? currentSegmentName : $t('profilehub.contacts.navigation.default.all')"
                  large
                />
                <UiSubtitle :subtitle="currentSegment ? `${profilesPagination.total} contacts` : '0 contacts'" large />
              </div>
              <v-spacer />
              <v-badge
                v-if="currentSegmentName"
                class="tw-ml-4"
                overlap
                color="primary"
                :content="currentSegment.rules.filters.length"
                :value="currentSegment.rules.filters.length"
                ><v-btn
                  v-if="currentSegmentName"
                  outlined
                  color="primary"
                  rounded
                  :icon="$vuetify.breakpoint.xsOnly"
                  @click="toggleFilters(true)"
                >
                  <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiFilterOutline }}</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('button.filter') }}</span>
                </v-btn></v-badge
              >
              <v-btn
                class="tw-ml-4"
                :fab="$vuetify.breakpoint.xsOnly"
                :small="$vuetify.breakpoint.xsOnly"
                :elevation="2"
                v-if="currentSegmentName && currentSegment.id === 0"
                color="primary"
                rounded
                :disabled="cantSaveSegment"
                :icon="$vuetify.breakpoint.xsOnly"
                @click="openSave"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiContentSaveOutline }}</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('button.save') }}</span>
              </v-btn>
              <v-btn
                class="tw-ml-4"
                :fab="$vuetify.breakpoint.xsOnly"
                :small="$vuetify.breakpoint.xsOnly"
                :elevation="2"
                v-else-if="currentSegmentName && currentSegment.id !== 0"
                color="primary"
                rounded
                :disabled="cantSaveSegment"
                :icon="$vuetify.breakpoint.xsOnly"
                @click="openSave"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiContentCopy }}</v-icon>
                <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('profilehub.contacts.cta.clone') }}</span>
              </v-btn>
            </div>
          </template>
          <template v-if="currentSegment.id !== 0" v-slot:actions
            ><!-- 
            <v-btn rounded color="primary" class="tw-mr-2" v-if="$vuetify.breakpoint.smAndUp"> Activer </v-btn> -->
            <v-menu
              offset-y
              left
              :close-on-content-click="true"
              max-width="300"
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn icon v-bind="attrs" v-on="{ ...menu }" class="tw-ml-4 tw---mr-1">
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <!-- <v-list-item link v-if="$vuetify.breakpoint.xsOnly">
                  <v-list-item-icon class="tw-mr-4">
                    <v-icon>{{ icons.mdiEmailSendOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Activer</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
                <v-list-item link @click="goToExportPage">
                  <v-list-item-icon class="tw-mr-4">
                    <v-icon>{{ icons.mdiExportVariant }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('button.export') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="goToImportPage">
                  <v-list-item-icon class="tw-mr-4">
                    <v-icon>{{ icons.mdiImport }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('button.import') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link v-if="currentSegmentName" @click="openRename">
                  <v-list-item-icon class="tw-mr-4">
                    <v-icon>{{ icons.mdiFormTextbox }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('profilehub.contacts.cta.rename') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link v-if="currentSegmentName" @click="openDelete">
                  <v-list-item-icon class="tw-mr-4">
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('button.delete') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </UiBanner>
        <UiContainer no-padding>
          <div
            v-if="!currentSegmentName"
            class="profile-hub-contacts-list__content__toolbar"
            :class="{ 'theme--dark': $vuetify.theme.dark }"
          >
            <!-- <v-btn-toggle v-model="//" dense rounded color="primary" borderless>
              <v-tooltip bottom transition="fade-transition">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn v-on="{ ...tooltip }">
                    <v-icon>{{ icons.mdiFormatListBulleted }}</v-icon>
                  </v-btn>
                </template>
                Liste
              </v-tooltip>
              <v-tooltip bottom transition="fade-transition">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn v-on="{ ...tooltip }">
                    <v-icon>{{ icons.mdiViewDashboardOutline }}</v-icon>
                  </v-btn>
                </template>
                Tableau de bord
              </v-tooltip>
            </v-btn-toggle> -->
            <v-text-field
              v-model.trim="search"
              solo
              flat
              outlined
              placeholder="Recherche"
              dense
              :prepend-inner-icon="icons.mdiMagnify"
              hide-details
              @input="searchByEmail"
            />
          </div>
          <div
            class="profile-hub-contacts-list__content__table-container"
            :class="{ 'profile-hub-contacts-list__content__table-container--search': !currentSegmentName }"
          >
            <v-data-table
              :headers="headers"
              :items="profiles"
              fixed-header
              height="100%"
              class="profile-hub-contacts-list__content__table-container__table"
              :options.sync="options"
              :server-items-length="profilesPagination.total"
              :footer-props="{
                itemsPerPageOptions: [25, 50, 100],
              }"
              :loading="refreshing"
              @click:row="getIndividualView"
            >
              <template v-slot:[`item.criterias.optin`]="{ item }">
                <v-btn
                  v-if="item.criterias.optin === '0'"
                  color="error"
                  icon
                  :disabled="opting && optedOutItemId === item.id"
                  :loading="opting && optedOutItemId === item.id"
                  @click.stop="openConfirmOptIn(item)"
                >
                  <v-icon>{{ icons.mdiToggleSwitchOff }}</v-icon>
                </v-btn>
                <v-btn
                  v-else-if="item.criterias.optin === '1'"
                  color="success"
                  icon
                  :disabled="opting && optedOutItemId === item.id"
                  :loading="opting && optedOutItemId === item.id"
                  @click.stop="toggleOptIn(item, '0')"
                >
                  <v-icon>{{ icons.mdiToggleSwitch }}</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.criterias.pays`]="{ item }">
                <v-img v-if="getFlagSrc(item)" :src="getFlagSrc(item)" max-width="24" />
                <span v-else>
                  {{ item.criterias.pays }}
                </span>
              </template>
            </v-data-table>
          </div>
        </UiContainer>
      </div>
      <ProfileHubContactsOptinConfirm
        ref="optinConfirm"
        :dialog="dialogConsent"
        :item="selectedItem"
        @close="closeModal"
        @confirm="toggleOptIn($event, '1')"
        :loading="opting"
      />
      <ProfileHubContactsFilters
        ref="filters"
        :segment="currentSegment"
        :criteria="criteria"
        :drawer="drawerFilters"
        @toggle="toggleFilters"
        @filter="filterView"
      />
      <ProfileHubContactsNameSegment
        ref="save"
        :segment="currentSegment"
        :dialog="dialogSave"
        :mode="saveMode"
        :loading="saving"
        @close="closeModal"
        @save="saveCurrentSegment"
      />
      <UiConfirmOverlay
        ref="delete"
        :active="dialogDelete"
        :title="$t('profilehub.contacts.modal.deleteSegment.title')"
        :body="$t('profilehub.contacts.modal.deleteSegment.subtitle', { name: currentSegmentName })"
        :loading="refreshing"
        @close="closeModal"
        @confirm="removeSegment"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import {
  mdiMenuOpen,
  mdiDotsVertical,
  mdiViewDashboardOutline,
  mdiFormatListBulleted,
  mdiMagnify,
  mdiFilterOutline,
  mdiEyePlusOutline,
  mdiEyeOffOutline,
  mdiContentSaveOutline,
  mdiExportVariant,
  mdiTrashCanOutline,
  mdiFormTextbox,
  mdiEmailSendOutline,
  mdiToggleSwitchOff,
  mdiToggleSwitch,
  mdiContentCopy,
  mdiContentSaveAlertOutline,
  mdiImport,
} from '@mdi/js'
import { mapActions, mapState, mapGetters } from 'vuex'
import { debounce } from '@/utils/utilities.util'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiBanner from '@/components/UI/Banner.vue'
import ProfileHubContactsOptinConfirm from '@/components/ProfileHub/Contacts/OptinConfirm.vue'
import ProfileHubContactsNameSegment from '@/components/ProfileHub/Contacts/NameSegment.vue'
import ProfileHubContactsFilters from '@/components/ProfileHub/Contacts/Filters.vue'
import UiConfirmOverlay from '@/components/UI/ConfirmOverlay.vue'

export default {
  name: 'ProfileHubContactsList',
  components: {
    UiContainer,
    UiBanner,
    UiTitle,
    UiSubtitle,
    ProfileHubContactsOptinConfirm,
    ProfileHubContactsFilters,
    ProfileHubContactsNameSegment,
    UiConfirmOverlay,
  },
  data: () => ({
    dialogConsent: false,
    drawerFilters: false,
    dialogSave: false,
    dialogDelete: false,
    saveMode: '',
    drawerSegments: null,
    headers: [
      {
        text: 'Optin',
        align: 'start',
        sortable: false,
        value: 'criterias.optin',
      },
      { text: 'Email', value: 'criterias.email' },
      { text: 'Prénom', value: 'criterias.prenom', sortable: false },
      { text: 'Nom', value: 'criterias.nom', sortable: false },
      { text: 'Genre', value: 'criterias.genre', width: 110, sortable: false },
      { text: 'Pays', value: 'criterias.pays', sortable: false },
    ],
    icons: {
      mdiMenuOpen,
      mdiDotsVertical,
      mdiViewDashboardOutline,
      mdiFormatListBulleted,
      mdiMagnify,
      mdiFilterOutline,
      mdiEyePlusOutline,
      mdiContentSaveOutline,
      mdiExportVariant,
      mdiTrashCanOutline,
      mdiFormTextbox,
      mdiEmailSendOutline,
      mdiToggleSwitchOff,
      mdiToggleSwitch,
      mdiEyeOffOutline,
      mdiContentCopy,
      mdiContentSaveAlertOutline,
      mdiImport,
    },
    search: '',
    selectedItem: null,
    refreshing: false,
    saving: false,
    opting: false,
    optedOutItemId: null,
    options: {},
  }),
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (newOptions !== oldOptions) this.loadProfiles()
      },
      deep: true,
    },
  },
  async created() {
    this.setUpdating(true)
    try {
      await Promise.all([this.getSegments(), this.getCriteria()])
    } catch {
      this.setAlert({
        color: 'error',
        text: this.$t('error.notification.default'),
      })
    }
    this.setUpdating(false)
  },
  mounted() {
    window.addEventListener('beforeunload', this.warnUser)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      currentBrand: state => state.backoffice.currentClient?.brandId,
      segments: state => state.profilehub.segments,
      currentSegment: state => state.profilehub.currentSegment,
      profiles: state => state.profilehub.profiles,
      profilesPagination: state => state.profilehub.profilesPagination,
      criteria: state => state.profilehub.criteria,
    }),
    ...mapGetters({
      currentSegmentName: 'profilehub/currentSegmentName',
      tmpSegment: 'profilehub/tmpSegment',
      cantSaveSegment: 'profilehub/cantSaveSegment',
    }),
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      setCurrentSegment: 'profilehub/setCurrentSegment',
      setFilters: 'profilehub/setFilters',
      getProfiles: 'profilehub/getProfiles',
      getSegments: 'profilehub/getSegments',
      addSegment: 'profilehub/addSegment',
      updateSegment: 'profilehub/updateSegment',
      createSegment: 'profilehub/createSegment',
      getCriteria: 'profilehub/getCriteria',
      deleteSegment: 'profilehub/deleteSegment',
      setAlert: 'backoffice/setAlert',
      updateOptin: 'profilehub/updateOptin',
      setCurrentProfile: 'profilehub/setCurrentProfile',
    }),
    toggleSegments(bool) {
      this.drawerSegments = typeof bool === 'boolean' ? bool : !this.drawerSegments
    },
    openConfirmOptIn(item) {
      this.selectedItem = item
      this.dialogConsent = true
    },
    async toggleOptIn(item, newValue) {
      this.opting = true
      this.optedOutItemId = item.id
      try {
        await this.updateOptin({ profile: item, optinValue: newValue })
        this.setAlert({
          color: 'success',
          text: this.$t('profilehub.contacts.notification.optIn'),
        })
        this.closeModal()
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.opting = false
      this.optedOutItemId = null
    },
    toggleFilters(bool) {
      this.drawerFilters = bool
    },
    openSave() {
      this.saveMode = 'create'
      this.dialogSave = true
    },
    openDelete() {
      this.dialogDelete = true
    },
    openRename() {
      this.saveMode = 'update'
      this.dialogSave = true
    },
    searchByEmail: debounce(function (value) {
      const segment = {
        ...this.currentSegment,
        rules: {
          ...this.currentSegment.rules,
          filters: [
            {
              slug: 'email',
              values: [value],
              operator: 'contains',
            },
          ],
        },
      }
      this.setCurrentSegment(segment)

      this.resetOptions()
    }, 300),
    closeModal() {
      this.dialogConsent = false
      this.drawerFilters = false
      this.dialogSave = false
      this.dialogDelete = false
      this.$refs.optinConfirm.resetData()
      this.$refs.filters.resetData()
    },
    async loadProfiles() {
      this.refreshing = true
      await this.getProfiles({ tableParams: this.options })
      this.refreshing = false
    },
    updateCurrentSegment(segment) {
      this.setCurrentSegment(segment)
      this.resetOptions()
    },
    filterView(filters) {
      const clearedFilters = filters.filter(filter => filter.values.length !== 0)
      this.setFilters(clearedFilters)
      this.toggleFilters(false)
      this.resetOptions()
    },
    addNewSegment() {
      this.addSegment(this.filters)
      this.resetOptions()
    },
    getFlagSrc(item) {
      try {
        return require(`@/assets/images/flags/${item.criterias.pays.toLowerCase()}.svg`)
      } catch {
        return null
      }
    },
    async saveCurrentSegment(segment, mode) {
      this.saving = true
      try {
        if (mode === 'create') {
          await this.createSegment(segment)
          this.setAlert({
            color: 'success',
            text: this.$t('profilehub.contacts.notification.saveSegment'),
          })
        } else if (mode === 'update') {
          await this.updateSegment(segment)
          this.setAlert({
            color: 'success',
            text: this.$t('profilehub.contacts.notification.renameSegment'),
          })
        }
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.dialogSave = false
      this.saving = false
    },
    async removeSegment() {
      this.refreshing = true
      try {
        await this.deleteSegment()
        this.setAlert({
          color: 'info',
          text: this.$t('profilehub.contacts.notification.deleteSegment'),
        })
        this.resetOptions()
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.closeModal()
      this.refreshing = false
    },
    goToExportPage() {
      this.$router.push({ name: 'ProfileHubExport' })
    },
    goToImportPage() {
      this.$router.push({ name: 'ProfileHubImport' })
    },
    getIndividualView(item) {
      this.$router.push({ name: 'ProfileHubContactsIndividual', params: { id: item.id } })
    },
    warnUser(e) {
      e.preventDefault()
      if (this.tmpSegment) {
        e.returnValue = ''
      }
    },
    resetOptions() {
      this.options = {
        ...this.options,
        page: 1,
      }
    },
  },
  beforeDestroy() {
    if (this.tmpSegment) {
      window.removeEventListener('beforeunload', this.warnUser)
    }
  },
}
</script>

<style lang="scss">
.profile-hub-contacts-list {
  &__segments {
    z-index: 3 !important;

    &__header {
      @apply tw-p-4;
    }

    &__list {
      @apply tw-mt-6;
    }
  }

  &__content {
    @apply tw-w-full tw-h-full tw-flex tw-flex-col;

    transition: padding-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &--expanded {
      @media (min-width: map-get($grid-breakpoints, 'md')) {
        padding-left: 256px;
      }
    }

    &__toolbar {
      @apply tw-flex tw-items-center tw-gap-4 tw-p-4 sm:tw-px-6;

      border-bottom: 1px solid map-deep-get($material-light, 'banner', 'border');
      height: $toolbar-height;

      &.theme--dark {
        border-bottom: 1px solid map-deep-get($material-dark, 'banner', 'border');
      }
    }

    &__table-container {
      @apply tw-absolute tw-right-0 tw-bottom-0 tw-left-0;

      top: 0;

      &--search {
        top: $toolbar-height;
      }

      &__table {
        @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;

        tbody tr:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
